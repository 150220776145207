@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.6');
    box-shadow: theme('boxShadow.xl');
  }
  /* ... */
}

.splashBg {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex:1;
  width:100%; 
  background: url('./images/mountains.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100vh;

}

.splashBg::before {
  content:"";
  background-color:rgba(0,0,0,.25);
}

.centerHero {
  text-align: center;
  max-width: 60%;
  color:white;
  display:flex;
  flex-direction: column;
}

.centerHero button {
  align-self: center;
}

.spacer {
  flex:.7;
}

.splashBg h1 {
  text-shadow: 2px 2px 2px rgb(0,0,0,.99);
  font-size: 8.0vh;
  margin-bottom: 0px;
}

.subtitleText {
  font-weight: 400;
  font-size: 1.25em;
  color:white;
  text-shadow: 1px 1px 0px rgb(0,0,0,.8);
  margin-top: -5px;
  margin-bottom:35px;
}

.topMenuItem {
  margin-right: 15px;
  margin-left: 15px;
}

.flexContainer {
  display:flex;
}

.flexColumn {
  flex-direction: column;
}

.flexRow {
  flex-direction:row;
}

.flexItem {
  flex:1;
}

.tabPanel {
  flex-direction:column;
}

.sidebar {
  max-width: 240px;
  border-right: 1px solid #ccc;
}

.sidebar ul {
  padding-top:0px;
}